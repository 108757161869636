import { useState } from 'react'
import dayjs from 'dayjs'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import remove from 'lodash/remove'
import { Badge, Button, Checkbox, Col, Divider, Empty, Form, Modal, Popconfirm, Row, Table, Tabs, Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	AppleOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloudDownloadOutlined,
	DeleteOutlined,
	DesktopOutlined,
	EditOutlined,
	EuroCircleOutlined,
	ExclamationCircleFilled,
	FacebookOutlined,
	FieldTimeOutlined,
	GlobalOutlined,
	GoogleOutlined,
	LinkOutlined,
	MailOutlined,
	MobileOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
	ReadOutlined,
	RollbackOutlined,
	SaveOutlined,
	SettingOutlined,
	StopOutlined,
	TabletOutlined,
	ToolOutlined,
	UserSwitchOutlined
} from '@ant-design/icons'

// redux
import { RootState } from '../../../redux'
import { cancelSubscription, deleteSubscription, generateTempPassword } from '../../../redux/user/userActions'
import { deleteUser, deleteUserProgram, getUserProgram, getUserPrograms, getUserUserAgents, updateUserProgram } from '../../../redux/users/actions'
import { getPaymentInvoice, getUserPayments } from '../../../redux/payments/actions'
import { getUserSubscriptions, getUserSubscriptionsTierValidate } from '../../../redux/subscriptions/actions'

// utils
import { uploadFile } from '../../../utils/fileUploader'
import {
	formatPaymentMethod,
	formatPaymentState,
	formatPaymentType,
	formatProgramDifficulty,
	formatSubscriptionType,
	getErrorFieldsLabel,
	getFieldLabel,
	getPaymentStateColor
} from '../../../utils/helpers'
import {
	EMPTY_VALUE,
	FORMS,
	LOGIN_TYPES,
	PAYMENT_METHOD,
	PAYMENT_STATE,
	PAYMENT_TYPE,
	PLATFORM_TYPE,
	PROGRAM_DIFFICULTY,
	SOCIALS,
	UPLOAD_CATEGORY,
	USER_TABS
} from '../../../utils/enums'

// types
import { IFieldLabels } from '../../../types/interfaces'
import { PatchUsersUserIdProgramsUserProgramIdBody } from '../../../redux/users/types'

// forms
import UserProgramForm from './UserProgramForm'
import validateUserForm, { UserFormValues } from './validateUserForm'

// components
import SubscriptionsTable from '../components/SubscriptionsTable/SubscriptionsTable'

// atoms
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

const PAGE_SIZE = 20

type UserFormProps = {
	handleSubmit: () => void
	handleSwitchUser: () => void
	handleAddSubscription: () => void
	setShowPaymentModal: (value: boolean) => void
	handleShowCreateProgramModal: (value: boolean) => void
	handleSubmitUserProgram: (values: any, id: any, isChallenge: boolean, challengeType: any) => void
	handleRefund: (value: any) => void
	setShowTransferSubscriptionModal: (value: boolean) => void
	setShowEditSubscriptionModal: (arg: boolean) => void
	setShowRecipeBooksModal: (value: boolean) => void
	setShowPasswordChangeModal: (value: boolean) => void
	showPayments: (value: any) => void
	isCreate?: boolean
}

const UserForm = ({
	isCreate = false,
	handleSubmit,
	invalid,
	pristine,
	handleSwitchUser,
	handleRefund,
	handleAddSubscription,
	setShowTransferSubscriptionModal,
	setShowEditSubscriptionModal,
	setShowRecipeBooksModal,
	setShowPasswordChangeModal,
	showPayments,
	setShowPaymentModal,
	handleShowCreateProgramModal,
	handleSubmitUserProgram
}: UserFormProps & InjectedFormProps<UserFormValues, UserFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const [expandedRows, setExpandedRows] = useState<any>([])
	const [currentTab, setCurrentTab] = useState<string>(USER_TABS.GENERAL)
	const [tempPassword, setTempPassword] = useState<string>()

	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.USER_FORM)(state)) as UserFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.USER_FORM))

	const [filter, setFilter] = useState<any>({
		paymentStates: []
	})

	const payments = useSelector((state) => get(state, 'payments.userPayments.payments'))
	const context = useSelector((state) => get(state, 'payments.userPayments.context'))

	const programs = useSelector((state) => get(state, 'users.userPrograms.data.programs'))
	const userAgents = useSelector((state: RootState) => state.users?.userAgents)

	const subscriptions = useSelector((state: RootState) => state.subscriptions?.userSubscriptions?.subscriptions)
	const tierChangePossible = useSelector((state: RootState) => state.subscriptions?.tierChangePossible)

	const preferences = useSelector((state) => get(state, 'select.preferences.list'))
	const userPreferences = map(get(fieldValues, 'preferences'), (item) => {
		const founded: any = find(preferences, { id: item })
		return {
			key: get(founded, 'id'),
			title: founded?.title ? founded.title : founded?.description
		}
	})

	const isLoadingPayments = useSelector((state) => get(state, 'payments.userPayments.isLoading'))
	const isLoadingSubscriptions = useSelector((state) => get(state, 'subscriptions.userSubscriptions.isLoading'))

	// constants
	const fieldLabels: IFieldLabels = {
		firstName: t('Meno'),
		lastName: t('Priezvisko'),
		contactEmail: t('Kontaktný email'),
		phoneNumber: t('Telefón'),
		height: t('Výška')
	}

	const resetFilter = () => {
		setFilter({ paymentStates: [] })
	}

	const handleCancelSubscription = (record: any) => {
		Modal.confirm({
			title: t('Skutočne si prajete ukončiť členstvo?'),
			icon: <StopOutlined type={'danger'} />,
			okText: t('Ukončiť'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					cancelSubscription(fieldValues?.id, record?.id, () => {
						dispatch(getUserSubscriptions(fieldValues?.id))
						dispatch(getUserSubscriptionsTierValidate(fieldValues?.id))
					})
				)
			}
		})
	}

	const handleRemoveSubscription = (record: any) => {
		Modal.confirm({
			title: t('Skutočne si prajete odstrániť členstvo?'),
			icon: <DeleteOutlined />,
			okText: t('Odstrániť'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteSubscription(get(fieldValues, 'id'), get(record, 'id'), () => {
						dispatch(getUserSubscriptions(get(fieldValues, 'id')))
						dispatch(getUserSubscriptionsTierValidate(get(fieldValues, 'id')))
					})
				)
			}
		})
	}

	const columns = [
		{
			title: t('ID'),
			key: 'id',
			ellipsis: true,
			width: 50,
			render: (_type: any, record: any) =>
				(record.paymentMethod === PAYMENT_METHOD.IOS_IN_APP_PURCHASE || record.paymentMethod === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE
					? record.paymendID
					: record.id) || EMPTY_VALUE
		},
		{
			title: t('Číslo faktúry'),
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			ellipsis: true,
			width: 110
		},
		{
			title: t('Typ platby'),
			dataIndex: 'paymentType',
			key: 'paymentType',
			sorter: true,
			render: (type: string, record: any) => `${type ? formatPaymentType(type, get(record, 'subscriptionType')) : EMPTY_VALUE} `,
			width: 120
		},
		{
			title: '',
			dataIndex: 'isRefundable',
			key: 'isRefundable',
			width: 32,
			render: (type: boolean, record: any) => (
				<span>
					{(() => {
						if (
							get(record, 'paymentMethod') === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE ||
							get(record, 'paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE
						) {
							const text =
								get(record, 'paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE
									? `${t(
											'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Apple konzolu podľa identifikátoru platby '
										)}${get(record, 'paymentID') || EMPTY_VALUE}`
									: `${t(
											'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Google konzolu podľa identifikátoru platby '
										)}${get(record, 'paymentID') || EMPTY_VALUE}`

							return (
								<Tooltip placement={'bottom'} title={text}>
									<span style={{ color: '#dacb00' }}>
										<MobileOutlined />
									</span>
								</Tooltip>
							)
						}

						if (
							!record.isRefundable &&
							get(record, 'paymentState') !== PAYMENT_STATE.FAILED &&
							get(record, 'paymentType') !== PAYMENT_TYPE.REFUND &&
							!get(record, 'isRefunded')
						) {
							return (
								<Tooltip placement={'bottom'} title={t('Nie je možné automaticky refundovať túto platbu')}>
									<span style={{ color: '#dacb00' }}>
										<ExclamationCircleFilled />
									</span>
								</Tooltip>
							)
						}

						return null
					})()}
				</span>
			)
		},
		{
			title: t('Platobná metóda'),
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			sorter: true,
			render: (method: string) => (method ? formatPaymentMethod(method) : EMPTY_VALUE)
		},
		{
			title: t('Dátum vytvorenia'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			sorter: true,
			ellipsis: true,
			render: (date: any) => (date && date !== 0 ? dayjs(date).format('D.M.YYYY HH:mm') : EMPTY_VALUE)
		},
		{
			title: t('Dátum spracovania'),
			dataIndex: 'processedAt',
			key: 'processedAt',
			ellipsis: true,
			render: (date: any) => (date && date !== 0 ? dayjs(date).format('D.M.YYYY HH:mm') : EMPTY_VALUE)
		},
		{
			title: t('Členstvo'),
			dataIndex: 'subscriptionType',
			key: 'subscriptionType',
			ellipsis: true,
			render: (text: string, record: any) => (
				<>
					{formatSubscriptionType(text, get(record, 'paymentType'))}
					<br />
					<span style={{ fontSize: 10 }}>{`${record.note ? `${record.note}` : ''}`}</span>
				</>
			)
		},
		{
			title: t('Stav'),
			dataIndex: 'paymentState',
			key: 'paymentState',
			ellipsis: true,
			width: 97,
			render: (state: string, record: any) => (
				<Badge
					count={`${formatPaymentState(state, record?.isRefunded, record?.paymentType)}`}
					style={{ backgroundColor: getPaymentStateColor(state, record?.isRefunded, record?.paymentType) }}
				/>
			)
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right' as const,
			width: 130,
			align: 'right' as const,
			render: (text: string, record: any) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:payment|path')}/${get(record, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					{get(record, 'paymentState') !== PAYMENT_STATE.FAILED &&
						get(record, 'paymentType') &&
						get(record, 'paymentType') !== PAYMENT_TYPE.REFUND &&
						!get(record, 'isRefunded') &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE && (
							<Popconfirm
								title={t('Skutočne si prajete refundovať platbu?')}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								cancelText={t('Zrušiť')}
								okText={t('Refundovať')}
								onConfirm={(e: any) => {
									e.stopPropagation()
									handleRefund(record.id)
								}}
								onCancel={(e: any) => e.stopPropagation()}
								okButtonProps={{
									size: 'small',
									type: 'primary',
									danger: true
								}}
								cancelButtonProps={{
									size: 'small',
									type: 'ghost'
								}}
							>
								<Tooltip
									title={
										get(record, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_BANK ||
										get(record, 'paymentMethod') === PAYMENT_METHOD.BANK_TRANSACTION ||
										get(record, 'paymentMethod') === PAYMENT_METHOD.SMS
											? t('Iba vytvorte faktúry, refundácia nebude automaticky uplatnená')
											: t('Refundovať')
									}
									placement={'bottom'}
								>
									<Button
										icon={<RollbackOutlined />}
										type={'primary'}
										danger
										style={{ marginRight: 4 }}
										onClick={(e) => e.stopPropagation()}
									/>
								</Tooltip>
							</Popconfirm>
						)}

					{get(record, 'paymentMethod') !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE && (
							<Button
								icon={<CloudDownloadOutlined />}
								type={'primary'}
								onClick={(e) => {
									e.stopPropagation()

									dispatch(
										getPaymentInvoice(record.id, (data: any) => {
											window.open(data, '_blank')
										})
									)
								}}
							/>
						)}
				</>
			)
		}
	]

	const userAgentRowBuilder = (row: any) => {
		let appType
		let loginType

		switch (row?.appType) {
			case PLATFORM_TYPE.WEB:
				appType = (
					<Tooltip title={t('Web')}>
						<GlobalOutlined />
					</Tooltip>
				)
				break
			case PLATFORM_TYPE.MOBILE:
				appType = (
					<Tooltip title={t('Mobil')}>
						<TabletOutlined />
					</Tooltip>
				)
				break
			case PLATFORM_TYPE.TV:
				appType = (
					<Tooltip title={t('Tv')}>
						<DesktopOutlined />
					</Tooltip>
				)
				break
			default:
				appType = <GlobalOutlined />
		}

		switch (row?.loginType) {
			case LOGIN_TYPES.GOOGLE:
				loginType = (
					<Tooltip title={t('Google účet')}>
						<GoogleOutlined />
					</Tooltip>
				)
				break
			case LOGIN_TYPES.APPLE:
				loginType = (
					<Tooltip title={t('Apple účet')}>
						<AppleOutlined />
					</Tooltip>
				)
				break
			case LOGIN_TYPES.FACEBOOK:
				loginType = (
					<Tooltip title={t('Facebook účet')}>
						<FacebookOutlined />
					</Tooltip>
				)
				break
			case LOGIN_TYPES.EMAIL:
				loginType = (
					<Tooltip title={t('Email')}>
						<MailOutlined />
					</Tooltip>
				)
				break
			default:
				loginType = <GlobalOutlined />
				break
		}

		return (
			<span>
				<span style={{ display: 'inline-block', marginRight: '14px' }}>{appType}</span>
				<span>{loginType}</span>
			</span>
		)
	}

	const userAgentsColumns = [
		{
			title: t('Dátum'),
			dataIndex: 'loginDate',
			key: 'loginDate',
			align: 'center' as const,
			ellipsis: true,
			width: 130,
			render: (date: string) => dayjs(date).format('YYYY.MM.DD HH:mm')
		},
		{
			title: t('Prihlásenie'),
			dataIndex: 'appType',
			key: 'appType',
			ellipsis: true,
			align: 'center' as const,
			width: 90,
			render: (_: any, row: any) => userAgentRowBuilder(row)
		},
		{
			title: t('UserAgent'),
			dataIndex: 'userAgent',
			key: 'userAgent',
			ellipsis: true,
			render: (item: string) => <span className={'ua'}>{item}</span>
		}
	]

	const [image, setImage] = useState({
		isLoading: false,
		imageUrl: ''
	})

	const yesNoOptions = [
		{ value: null, label: '' },
		{ value: true, label: t('Áno') },
		{ value: false, label: t('Nie') }
	]

	const genderOptions = [
		{ value: null, label: '' },
		{ value: 'FEMALE', label: t('Žena') },
		{ value: 'MALE', label: t('Muž') },
		{ value: 'NOT_DISCLOSED', label: t('Nechcem uviesť') }
	]

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať používateľa?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteUser(get(fieldValues, 'id'), () => {
						history.push(t('paths:users|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)

		if (fileUrl) {
			dispatch(change(FORMS.USER_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		dispatch(
			getUserPayments(get(fieldValues, 'id'), {
				limit: PAGE_SIZE,
				page: pagination.current,
				...filter,
				...order
			})
		)
	}

	const handleUserAgentTableChange = (pagination: any) => {
		dispatch(
			getUserUserAgents(get(fieldValues, 'id'), {
				limit: PAGE_SIZE,
				page: pagination.current
			})
		)
	}

	const handleChangePaymentState = (value: any, state: any) => {
		const oldArray = [...get(filter, 'paymentStates')]
		const paymentStates = !!value && get(value, 'target.checked') ? [...oldArray, state] : [...remove(oldArray, (item) => item !== state)]
		setFilter({ ...filter, page: 1, paymentStates })
		if (get(fieldValues, 'id') && currentTab === USER_TABS.INVOICES) {
			dispatch(
				getUserPayments(get(fieldValues, 'id'), {
					limit: PAGE_SIZE,
					page: 1,
					paymentStates
				})
			)
		}
	}

	const handleGenerateTempPassword = () => {
		dispatch(generateTempPassword(fieldValues?.id, (newPassword: string) => setTempPassword(newPassword)))
	}

	const disabledDate = (current: any) =>
		current && (current <= dayjs().startOf('day').subtract(100, 'years') || dayjs().startOf('day').subtract(15, 'years') <= current)

	const getSocialIcon = (name: string, index = null) => {
		switch (name) {
			case SOCIALS.FACEBOOK:
				return <FacebookOutlined key={index} />
			case SOCIALS.GOOGLE:
				return <GoogleOutlined key={index} />
			case SOCIALS.EMAIL:
				return <MailOutlined key={index} />
			default:
				return name
		}
	}

	const renderDifficultyText = (difficulty: PROGRAM_DIFFICULTY) => {
		switch (difficulty) {
			case PROGRAM_DIFFICULTY.NORMAL:
				return <p style={{ margin: 0, color: 'green' }}>{formatProgramDifficulty(difficulty)}</p>
			case PROGRAM_DIFFICULTY.HARD:
				return <p style={{ margin: 0, color: 'orange' }}>{formatProgramDifficulty(difficulty)}</p>
			default:
				return null
		}
	}

	const programColumns = [
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (item: string) => <b>{item}</b>
		},
		{
			title: t('Program/Výzva'),
			dataIndex: 'isChallenge',
			key: 'isChallenge',
			align: 'center' as const,
			sorter: true,
			render: (isChallenge: boolean) =>
				isChallenge ? (
					<Tooltip title={t('Výzva')}>
						<Button type={'primary'} danger>
							{'V'}
						</Button>
					</Tooltip>
				) : (
					<Tooltip title={t('Program')}>
						<Button type={'primary'}>{'P'}</Button>
					</Tooltip>
				)
		},
		{
			title: t('Náročnosť'),
			dataIndex: 'difficulty',
			key: 'difficulty',
			align: 'center' as const,
			ellipsis: true,
			render: (item: PROGRAM_DIFFICULTY) => renderDifficultyText(item)
		},
		{
			title: t('Súťažná'),
			dataIndex: 'isCompetition',
			key: 'isCompetition',
			render: (item: boolean, record: any) => {
				if (get(record, 'isChallenge'))
					return item ? <Badge count={`${t('Suťažná')}`} style={{ backgroundColor: 'rgb(121, 209, 124)' }} /> : <Badge count={`${t('Nesúťažná')}`} />
				return ''
			}
		},
		{
			title: t('Začiatok programu'),
			dataIndex: 'startedAt',
			key: 'startedAt',
			render: (date: string) => dayjs(date).format('YYYY.MM.DD HH:mm')
		},
		{
			title: t('Ukončenie programu'),
			dataIndex: 'finishedAt',
			key: 'finishedAt',
			render: (date: string) => (date !== null ? dayjs(date).format('YYYY.MM.DD HH:mm') : EMPTY_VALUE)
		},
		{
			title: t(''),
			dataIndex: 'isChallenge',
			key: 'markChallengeCompleted',
			fixed: 'right' as const,
			width: 60,
			render: (isChallenge: boolean, record: any) => {
				if (isChallenge) {
					const isChallengeFinished = dayjs() > dayjs(record?.finishedAt)
					const body: PatchUsersUserIdProgramsUserProgramIdBody = {
						...record,
						markChallengeCompleted: true
					}

					return (
						<Tooltip title={isChallengeFinished ? t('Označiť ako absolvovanú') : t('Výzva ešte nebola ukončená')}>
							<Button
								icon={<CheckCircleOutlined />}
								type={'primary'}
								onClick={() =>
									dispatch(
										updateUserProgram(get(fieldValues, 'id'), record?.id, body, () => dispatch(getUserPrograms(get(fieldValues, 'id'))))
									)
								}
								disabled={!isChallengeFinished}
							/>
						</Tooltip>
					)
				}
				return ''
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right' as const,
			width: 60,
			render: (text: any, record: any) => (
				<Popconfirm
					title={t('Skutočne chcete vymazať program uživatelovi?')}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					cancelText={t('Zrušiť')}
					okText={t('Vymazať')}
					onConfirm={(e: any) => {
						e.stopPropagation()
						dispatch(
							deleteUserProgram(get(fieldValues, 'id'), get(record, 'id'), () => {
								dispatch(getUserPrograms(get(fieldValues, 'id')))
							})
						)
					}}
					onCancel={(e: any) => e.stopPropagation()}
					okButtonProps={{
						size: 'small',
						type: 'primary',
						danger: true
					}}
					cancelButtonProps={{
						size: 'small',
						type: 'ghost'
					}}
				>
					<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
				</Popconfirm>
			)
		}
	]

	const preferencesColumns = [
		{
			title: t('Preferencie'),
			dataIndex: 'title',
			key: 'key',
			ellipsis: true
		}
	]

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<h1>{t('Detail používateľa')}</h1>
						<Tabs defaultActiveKey={USER_TABS.GENERAL} className={'translations'} onChange={(tab) => setCurrentTab(tab)}>
							<TabPane tab={t('Všeobecné')} key={USER_TABS.GENERAL}>
								<Field name={'firstName'} component={TextField} label={t('Meno')} />
								<Field name={'lastName'} component={TextField} label={t('Priezvisko')} />
								<Field name={'email'} component={TextField} label={t('Registračný email')} />
								<Field name={'contactEmail'} component={TextField} label={t('Kontaktný email')} />
								<Field name={'phoneNumber'} component={TextField} label={t('Telefón')} />
								<Field name={'gender'} component={SelectField} label={t('Pohlavie')} options={genderOptions} />
								<Field name={'isPregnant'} component={SelectField} label={t('Čaká dieťa')} options={yesNoOptions} />
								<Field
									name={'birthdate'}
									component={DateField}
									disabledDate={disabledDate}
									defaultPickerValue={dayjs().subtract(15, 'years')}
									label={t('Dátum narodenia')}
									dttmFormat={'D.M.YYYY'}
								/>
								<Field name={'height'} type={'number'} component={TextField} label={t('Výška')} />
							</TabPane>
							<TabPane tab={t('Programy')} key={USER_TABS.PROGRAMS}>
								<Button
									icon={<PlusCircleOutlined />}
									onClick={() => {
										setExpandedRows([])
										handleShowCreateProgramModal(true)
									}}
									type={'primary'}
									style={{ marginBottom: '16px' }}
								>
									{t('Pridať program')}
								</Button>
								<Table
									columns={programColumns}
									className={'user-program-table'}
									dataSource={programs}
									rowKey={(record) => `${get(record, 'id')}-${get(record, 'name')}`}
									expandable={{
										expandedRowKeys: expandedRows,
										expandedRowRender: (record: any) => {
											return (
												<UserProgramForm
													programName={get(record, 'name')}
													programDifficulties={record?.difficulties}
													isChallenge={get(record, 'isChallenge')}
													onSubmit={(values: any) =>
														handleSubmitUserProgram(
															values,
															get(record, 'id'),
															get(record, 'isChallenge'),
															get(record, 'challengeType')
														)
													}
												/>
											)
										},
										onExpand: (expanded, record) => {
											if (expanded) {
												setExpandedRows([`${get(record, 'id')}-${get(record, 'name')}`])
												dispatch(getUserProgram(get(fieldValues, 'id'), get(record, 'id')))
											} else {
												setExpandedRows([])
											}
										},
										expandRowByClick: true
									}}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									pagination={false}
								/>
							</TabPane>
							<TabPane tab={t('Preferencie')} key={USER_TABS.PREFERENCES}>
								<Table
									className={'general-table'}
									columns={preferencesColumns}
									dataSource={userPreferences}
									onChange={handleTableChange}
									showSorterTooltip={false}
									rowKey={'key'}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
								/>
							</TabPane>
							<TabPane tab={t('Platby')} key={USER_TABS.INVOICES}>
								<>
									<Row>
										<Button
											icon={<EuroCircleOutlined />}
											onClick={() => setShowPaymentModal(true)}
											type={'primary'}
											style={{ height: '36px' }}
										>
											{t('Vytvoriť platbu')}
										</Button>
										<Divider type={'vertical'} style={{ height: '36px', margin: '0px 16px' }} />
										<div className={'flex paymentFilter checkboxes'} style={{ flexWrap: 'wrap', margin: '0px 16px 0px 0px' }}>
											<Form.Item>
												<Checkbox
													onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.FINISHED)}
													checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.FINISHED)}
												>
													{formatPaymentState(PAYMENT_STATE.FINISHED)}
												</Checkbox>
											</Form.Item>
											<Form.Item>
												<Checkbox
													onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.FAILED)}
													checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.FAILED)}
												>
													{formatPaymentState(PAYMENT_STATE.FAILED)}
												</Checkbox>
											</Form.Item>
											<Form.Item>
												<Checkbox
													onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.INITIALIZED)}
													checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.INITIALIZED)}
												>
													{formatPaymentState(PAYMENT_STATE.INITIALIZED)}
												</Checkbox>
											</Form.Item>
											<Form.Item>
												<Checkbox
													onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.PREAUTHORIZED)}
													checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.PREAUTHORIZED)}
												>
													{formatPaymentState(PAYMENT_STATE.PREAUTHORIZED)}
												</Checkbox>
											</Form.Item>
										</div>
										<Button style={{ height: '36px' }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
											{t('Zrušiť filter')}
										</Button>
									</Row>
									<Table
										className={'general-table'}
										columns={columns}
										dataSource={payments}
										onChange={handleTableChange}
										showSorterTooltip={false}
										rowKey={'id'}
										pagination={{
											pageSize: PAGE_SIZE,
											total: get(context, 'totalCount'),
											current: get(context, 'page'),
											showSizeChanger: false
										}}
										onRow={(record) => ({
											onClick: () => history.push(`${t('paths:payment|path')}/${get(record, 'id')}`)
										})}
										loading={isLoadingPayments}
										locale={{
											emptyText: <Empty description={t('Žiadne dáta')} />
										}}
										size={'small'}
									/>
								</>
							</TabPane>
							<TabPane tab={t('Členstvá')} key={USER_TABS.SUBSCRIPTIONS}>
								<SubscriptionsTable
									showPayments={showPayments}
									cancelSubscription={handleCancelSubscription}
									removeSubscription={handleRemoveSubscription}
								/>
							</TabPane>
							<TabPane tab={t('Prihlásenia')} key={USER_TABS.DEVICES}>
								<Table
									className={'general-table overflow-x-scroll'}
									columns={userAgentsColumns}
									dataSource={userAgents?.data?.userAgents}
									onChange={handleUserAgentTableChange}
									showSorterTooltip={false}
									rowKey={'id'}
									pagination={{
										pageSize: PAGE_SIZE,
										total: get(userAgents?.data?.context, 'totalCount'),
										current: get(userAgents?.data?.context, 'page'),
										showSizeChanger: false
									}}
									loading={isLoadingSubscriptions}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
								/>
							</TabPane>
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'lastLoginAt'} component={TextField} disabled label={t('Dátum posledného prihlásenia')} />}

						<div className={'input-wrapper'}>
							<label className={'socials-label'}>{t('Typ prihlásenia')}</label>
							<span className={'socials-icons'}>
								{fieldValues?.socials && fieldValues?.socials.length > 0
									? map(fieldValues?.socials, ({ name }, index: any) => getSocialIcon(name as string, index))
									: EMPTY_VALUE}
							</span>
						</div>

						{!isCreate && <Field name={'isConfirmed'} component={TextField} label={t('Potvrdenie emailu')} disabled />}

						<Field name={'subscription'} component={TextField} label={t('Členstvo')} disabled />

						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Profilová fotka (upload)')}
							customHelper={t('recommendedSize|profilePhoto')}
							customRequest={uploadImage}
							isLoading={image.isLoading}
						/>

						<Field name={'role'} component={SwitchField} label={t('Admin')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>
							{!isCreate && (
								<Button icon={<FieldTimeOutlined />} onClick={handleAddSubscription} style={{ margin: '20px' }}>
									{t('Pridať členstvo')}
								</Button>
							)}
							{!isCreate && (
								<Tooltip title={tierChangePossible === false && t('Členstvo nie je možné upraviť')}>
									<Button
										icon={<EditOutlined />}
										onClick={() => setShowEditSubscriptionModal(true)}
										style={{ margin: '20px' }}
										disabled={!tierChangePossible}
									>
										{t('Upraviť členstvo')}
									</Button>
								</Tooltip>
							)}
							{!isCreate && (
								<Button
									icon={<FieldTimeOutlined />}
									onClick={() => setShowTransferSubscriptionModal(true)}
									style={{ margin: '20px' }}
									disabled={subscriptions?.length <= 0}
									loading={isLoadingSubscriptions}
								>
									{t('Presunúť členstvo')}
								</Button>
							)}
							{!isCreate && (
								<Button icon={<ReadOutlined />} onClick={() => setShowRecipeBooksModal(true)} style={{ margin: '20px' }}>
									{t('Požiadať o jedálniček')}
								</Button>
							)}
							{!isCreate && (
								<Button icon={<UserSwitchOutlined />} onClick={handleSwitchUser} type={'primary'} style={{ margin: '20px' }}>
									{t('Prihlásiť sa')}
								</Button>
							)}
							{!isCreate && (
								<Button icon={<ToolOutlined />} onClick={() => setShowPasswordChangeModal(true)} type={'primary'} style={{ margin: '20px' }}>
									{t('Zmeniť používateľovi heslo')}
								</Button>
							)}
							{!isCreate &&
								(tempPassword ? (
									<Tooltip title={t('Skopírovať heslo')}>
										<Button onClick={() => navigator.clipboard.writeText(tempPassword)} style={{ margin: '20px' }}>
											{tempPassword}
										</Button>
									</Tooltip>
								) : (
									<Button icon={<SettingOutlined />} onClick={handleGenerateTempPassword} style={{ margin: '20px' }}>
										{t('Vygenerovať dočasné heslo')}
									</Button>
								))}
							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať používateľa')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<UserFormValues, UserFormProps>({
	form: FORMS.USER_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateUserForm
})(UserForm)
