import { useEffect, useCallback } from 'react'
import each from 'lodash/each'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createDownloadCategory, getDownloadCategory, updateDownloadCategory } from '../../redux/downloadCategories/actions'

// utils
import { FORMS, LANGUAGES } from '../../utils/enums'

// types
import {
	GetAdminDownloadCategoriesCategoryIdPayload,
	PostDownloadCategoriesBody,
	PutDownloadCategoriesCategoryIdBody
} from '../../redux/downloadCategories/types'
import { IComputedMatch } from '../../types/interfaces'

// forms
import DownloadCategoryForm from './forms/DownloadCategoryForm'
import { DownloadCategoryFormValues } from './forms/validateDownloadCategoryForm'

interface ILangData {
	id: number
	language: string
	name: string
}

type DetailDownloadCategoryProps = {
	computedMatch: IComputedMatch
}

const DetailDownloadCategory = ({ computedMatch }: DetailDownloadCategoryProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const { isLoading } = useSelector((state: RootState) => state.downloadCategories?.detail)

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {}

		dispatch(initialize(FORMS.DOWNLOAD_CATEGORY_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: GetAdminDownloadCategoriesCategoryIdPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				...data
			}

			forEach(langData, (item: ILangData) => {
				langValues[`name_${item.language}`] = item?.name
			})

			dispatch(
				initialize(FORMS.DOWNLOAD_CATEGORY_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getDownloadCategory(id, (data: GetAdminDownloadCategoriesCategoryIdPayload) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: PostDownloadCategoriesBody) => {
		dispatch(createDownloadCategory(body, () => history.push(t('paths:downloadCategories|path'))))
	}

	const handleUpdate = (body: PutDownloadCategoriesCategoryIdBody) => {
		dispatch(
			updateDownloadCategory(id, body, () =>
				dispatch(getDownloadCategory(id, (data: GetAdminDownloadCategoriesCategoryIdPayload) => initDetailForm(data)))
			)
		)
	}

	const handleSubmit = (values: DownloadCategoryFormValues) => {
		const body: any = {
			translations: []
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)

			if (name) {
				body.translations.push({
					name: get(values, `name_${item}`),
					language: item
				})
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<DownloadCategoryForm isCreate={!id} onSubmit={handleSubmit} />
			</Spin>
		</div>
	)
}
export default DetailDownloadCategory
