import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createChallenge, createProgram, getChallenge, getProgram, getProgramUsers, updateChallenge, updateProgram } from '../../redux/programs/actions'

// utils
import { CHALLENGE_TYPE, FORMS, LANGUAGE, LANGUAGES, PLATFORM_TYPE } from '../../utils/enums'

// types
import { IComputedMatch } from '../../types/interfaces'

import {
	GetChallengesIdPayload,
	GetProgramsIdPayload,
	PostChallengesBody,
	PostProgramsBody,
	PutChallengesIdBody,
	PutProgramsIdBody
} from '../../redux/programs/types'

// forms
import ProgramForm from './forms/ProgramForm'
import ChallengeForm from './forms/ChallengeForm'

export const DEFAULT_CHALLENGE_BACKGROUND_COLOR = '#ffffff'
export const DEFAULT_CHALLENGE_PRIMARY_COLOR = '#ff9946'
export const DEFAULT_CHALLENGE_SECONDARY_COLOR = '#ffc18c'

type DetailProgramProps = {
	computedMatch: IComputedMatch
}

const DetailProgram = ({ computedMatch }: DetailProgramProps) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const detail = useSelector((state: RootState) => state.programs?.detail)

	// states
	const [isLoadingPage, setIsLoadingPage] = useState(false)

	// constants
	const { id } = computedMatch.params
	const isLoading = get(detail, 'isLoading')
	const isChallenge =
		get(detail, 'data.isChallenge') ||
		get(computedMatch, 'path') === t('paths:challengeCreate|path') ||
		get(computedMatch, 'path') === t('paths:challenge|path')

	const getAllLists = useCallback(async () => {
		setIsLoadingPage(true)
		if (id) dispatch(getProgramUsers(id))
		setIsLoadingPage(false)
	}, [dispatch, id])

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			bodyParts: [],
			accessories: [],
			exercises: [],
			hardExercises: [],
			trainers: [],
			order: 1,
			allowedPlatforms: [PLATFORM_TYPE.WEB, PLATFORM_TYPE.TV, PLATFORM_TYPE.MOBILE],
			showExercisesInLists: false
		}

		if (isChallenge) {
			dispatch(
				initialize(FORMS.CHALLENGE_FORM, {
					...initValues,
					backgroundColor: DEFAULT_CHALLENGE_BACKGROUND_COLOR,
					primaryColor: DEFAULT_CHALLENGE_PRIMARY_COLOR,
					secondaryColor: DEFAULT_CHALLENGE_SECONDARY_COLOR,
					allowCompetition: false
				})
			)
		} else {
			dispatch(initialize(FORMS.PROGRAM_FORM, initValues))
		}
	}, [isChallenge, dispatch])

	const initDetailForm = useCallback(
		(data: GetProgramsIdPayload | GetChallengesIdPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				id: get(data, 'id'),
				publishDate: get(data, 'publishDate'),
				isPublished: get(data, 'isPublished'),
				showExercisesInLists: get(data, 'showExercisesInLists', false),
				isVisibleOnLandingPage: get(data, 'isVisibleOnLandingPage'),
				isLiveStreamCategory: get(data, 'isLiveStreamCategory'),
				isPregnantSuitable: get(data, 'isPregnantSuitable'),
				order: get(data, 'order') || 1,
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY '),
				exercises: map(data?.exercises, (item: any) => ({
					id: item?.id,
					title: item?.name,
					isVisibleInCalendar: item?.isVisibleInCalendar ?? false,
					isDifficultyRatingVisible: item?.isDifficultyRatingVisible ?? false
				})),
				hardExercises: map(data?.hardExercises, (item: any) => ({
					id: item?.id,
					title: item?.name,
					isVisibleInCalendar: item?.isVisibleInCalendar ?? false,
					isDifficultyRatingVisible: item?.isDifficultyRatingVisible ?? false
				})),
				trainers: map(get(data, 'trainers'), (item) => ({
					id: get(item, 'id'),
					title: get(item, 'name')
				})),
				motivationAdvices: map(get(data, 'motivationAdvices'), (item) => ({
					id: get(item, 'id'),
					title: get(item, 'name')
				})),
				preferences: get(data, 'preferences'),
				recommendedBlogPosts: get(data, 'recommendedBlogPosts'),
				allowedPlatforms: get(data, 'allowedPlatforms'),
				upsellProducts: map(get(data, 'upsellProducts'), (item: any) => ({ id: item.id, title: item.translations[0].name }))
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'urlSlug')
				langValues[`briefDescription_${item}`] = get(lang, 'briefDescription')
				langValues[`detailedDescription_${item}`] = get(lang, 'detailedDescription')
				langValues[`videoPreviewID_${item}`] =
					get(lang, 'videoPreview') !== null
						? [
								{
									id: get(lang, 'videoPreview.id'),
									title: get(lang, 'videoPreview.name')
								}
							]
						: []
				langValues[`stickerText_${item}`] = get(lang, 'stickerText')
				langValues[`recommendedProgramsText_${item}`] = get(lang, 'recommendedProgramsText')
				langValues[`smartEmailingContactListID_${item}`] = get(lang, 'smartEmailingContactListID')
				langValues[`smartEmailingContactListHardDifficultyID_${item}`] = get(lang, 'smartEmailingContactListHardDifficultyID')
				langValues[`stickerColor_${item}`] = get(lang, 'stickerColor')
				langValues[`image_${item}`] = get(lang, 'image.url')
				langValues[`logo_${item}`] = get(lang, 'logo.url')
				langValues[`inverseLogo_${item}`] = get(lang, 'inverseLogo.url')
				langValues[`whiteLogo_${item}`] = get(lang, 'whiteLogo.url')
			})

			if (get(data, 'isChallenge')) {
				const challenge = {
					users: get(data, 'users'),
					challengeType: get(data, 'challengeType', CHALLENGE_TYPE.COMPETITIVE),
					allowCompetition: get(data, 'allowCompetition', false),
					questions: get(data, 'questions'),
					startedAt: dayjs(get(data, 'startedAt')),
					finishedAt: dayjs(get(data, 'finishedAt')),
					visibleFrom: dayjs(get(data, 'visibleFrom')),
					visibleTo: dayjs(get(data, 'visibleTo')),
					backgroundColor: get(data, 'backgroundColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
					primaryColor: data?.primaryColor ?? DEFAULT_CHALLENGE_PRIMARY_COLOR,
					secondaryColor: data?.secondaryColor ?? DEFAULT_CHALLENGE_SECONDARY_COLOR,
					bannerImage: get(data, 'bannerImage.url'),
					largeBannerImage: get(data, 'largeBannerImage.url'),
					signupDeadline: dayjs(get(data, 'signupDeadline')),
					startMeasurementsDeadline: dayjs(get(data, 'startMeasurementsDeadline')),
					finishMeasurementsDeadline: dayjs(get(data, 'finishMeasurementsDeadline')),
					challengeUsers: null,
					recommendations: map(get(data, 'recommendations'), (item: any) => ({
						id: get(item, 'id'),
						title: find(get(item, 'translations'), ['language', LANGUAGE.SK])?.name
					})),
					firstGradientColor: get(data, 'gradientColors[0]', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
					secondGradientColor: get(data, 'gradientColors[1]', DEFAULT_CHALLENGE_BACKGROUND_COLOR)
				}

				forEach(LANGUAGES, (item) => {
					const lang = find(langData, { language: item })
					langValues[`prizeBanner_${item}`] = get(lang, 'prizeBanner.url')
					langValues[`badgeLogo_${item}`] = get(lang, 'badgeLogo.url')
					langValues[`lightBadgeLogo_${item}`] = get(lang, 'lightBadgeLogo.url')
					langValues[`domain_${item}`] = get(lang, 'domain')
					langValues[`prizeValue_${item}`] = get(lang, 'prizeValue')
					langValues[`conditionsUrl_${item}`] = get(lang, 'conditionsUrl')
					langValues[`faqUrl_${item}`] = get(lang, 'faqUrl')
				})

				dispatch(
					initialize(FORMS.CHALLENGE_FORM, {
						...initValues,
						...langValues,
						...challenge
					})
				)
			} else {
				dispatch(
					initialize(FORMS.PROGRAM_FORM, {
						...initValues,
						...langValues
					})
				)
			}
		},
		[dispatch]
	)

	const handleCreate = (body: PostProgramsBody | PostChallengesBody) => {
		if (isChallenge) {
			dispatch(createChallenge(body as PostChallengesBody, (challengeID) => history.push(`${t('paths:challenge|path')}/${challengeID}`)))
		} else {
			dispatch(createProgram(body, (programID) => history.push(`${t('paths:program|path')}/${programID}`)))
		}
	}

	const handleUpdate = (body: PutProgramsIdBody | PutChallengesIdBody) => {
		if (isChallenge) {
			dispatch(updateChallenge(id, body as PutChallengesIdBody, () => dispatch(getChallenge(id, (data) => initDetailForm(data)))))
		} else {
			dispatch(updateProgram(id, body as PutProgramsIdBody, () => dispatch(getProgram(id, (data) => initDetailForm(data)))))
		}
	}

	const handleSubmit = (values: any) => {
		const body: any = {
			translations: [],
			order: get(values, 'order'),
			publishDate: get(values, 'publishDate', dayjs()),
			isPublished: get(values, 'isPublished'),
			isVisibleOnLandingPage: get(values, 'isVisibleOnLandingPage'),
			isLiveStreamCategory: get(values, 'isLiveStreamCategory'),
			showExercisesInLists: get(values, 'showExercisesInLists', false),
			isPregnantSuitable: get(values, 'isPregnantSuitable'),
			recommendedBlogPosts: map(get(values, 'recommendedBlogPosts'), (item: any) => item.id),
			exercises: map(get(values, 'exercises', []), (item: any) => ({
				id: get(item, 'id'),
				isVisibleInCalendar: get(item, 'isVisibleInCalendar', false),
				isDifficultyRatingVisible: item?.isDifficultyRatingVisible ?? false
			})),
			hardExercises: map(values?.hardExercises, (item: any) => ({
				id: item?.id,
				isVisibleInCalendar: item?.isVisibleInCalendar ?? false,
				isDifficultyRatingVisible: item?.isDifficultyRatingVisible ?? false
			})),
			trainers: map(get(values, 'trainers', []), (item: any) => item.id),
			upsellProducts: map(get(values, 'upsellProducts'), (item: any) => item.id),
			motivationAdvices: map(get(values, 'motivationAdvices', []), (item: any) => item.id),
			preferences: map(get(values, 'preferences', []), (item: any) => item.id),
			allowedPlatforms: get(values, 'allowedPlatforms')
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)
			const briefDescription = get(values, `briefDescription_${item}`)
			const detailedDescription = get(values, `detailedDescription_${item}`)
			const videoPreview = get(values, `videoPreviewID_${item}`)
			const logo = get(values, `logo_${item}`)

			if (name && urlSlug && briefDescription && detailedDescription && videoPreview && logo) {
				const translation: any = {
					name: get(values, `name_${item}`),
					urlSlug: get(values, `urlSlug_${item}`),
					language: item,
					briefDescription: get(values, `briefDescription_${item}`),
					detailedDescription: get(values, `detailedDescription_${item}`),
					videoPreviewID: get(values, `videoPreviewID_${item}[0].id`),
					stickerText: get(values, `stickerText_${item}`),
					recommendedProgramsText: get(values, `recommendedProgramsText_${item}`),
					smartEmailingContactListID: toNumber(get(values, `smartEmailingContactListID_${item}`)),
					smartEmailingContactListHardDifficultyID: toNumber(get(values, `smartEmailingContactListHardDifficultyID_${item}`)),
					logo: get(values, `logo_${item}`),
					inverseLogo: get(values, `inverseLogo_${item}`),
					image: get(values, `image_${item}`)
				}

				if (get(values, `whiteLogo_${item}`)) {
					translation.whiteLogo = get(values, `whiteLogo_${item}`)
				}

				if (translation.stickerText) {
					translation.stickerColor = get(values, `stickerColor_${item}`) ? get(values, `stickerColor_${item}`) : '#FEA385'
				}

				if (isChallenge) {
					const challengeTranslation: any = {
						prizeBanner: get(values, `prizeBanner_${item}`),
						...((get(values, `badgeLogo_${item}`) && { badgeLogo: get(values, `badgeLogo_${item}`) }) as any),
						...((get(values, `lightBadgeLogo_${item}`) && { lightBadgeLogo: get(values, `lightBadgeLogo_${item}`) }) as any),
						domain: get(values, `domain_${item}`),
						prizeValue: get(values, `prizeValue_${item}`),
						conditionsUrl: get(values, `conditionsUrl_${item}`),
						faqUrl: get(values, `faqUrl_${item}`)
					}

					body.translations.push({
						...translation,
						...challengeTranslation
					})
				} else {
					body.translations.push(translation)
				}
			}
		})

		let challenge: any = {}

		if (isChallenge) {
			challenge = {
				challengeType: get(values, 'challengeType'),
				startedAt: dayjs(get(values, 'startedAt')).format('x'),
				finishedAt: dayjs(get(values, 'finishedAt')).format('x'),
				visibleFrom: dayjs(get(values, 'visibleFrom')).format('x'),
				visibleTo: dayjs(get(values, 'visibleTo')).format('x'),
				signupDeadline: dayjs(get(values, 'signupDeadline')).utc().valueOf(),
				startMeasurementsDeadline: dayjs(get(values, 'signupDeadline')).utc().valueOf(),
				finishMeasurementsDeadline: dayjs(get(values, 'finishMeasurementsDeadline')).utc().valueOf(),
				backgroundColor: get(values, 'backgroundColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
				bannerImage: get(values, 'bannerImage'),
				largeBannerImage: get(values, 'largeBannerImage'),
				isPublished: get(values, 'isPublished'),
				recommendedPrograms: map(get(values, 'recommendations', []), (item) => item.id),
				primaryColor: get(values, 'primaryColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
				secondaryColor: get(values, 'secondaryColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
				gradientColors: [
					get(values, 'firstGradientColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR),
					get(values, 'secondGradientColor', DEFAULT_CHALLENGE_BACKGROUND_COLOR)
				]
			}

			if (get(values, 'challengeType') === CHALLENGE_TYPE.COMPETITIVE) {
				challenge.allowCompetition = get(values, 'allowCompetition', false)
			}
		}

		if (id) {
			handleUpdate({
				...body,
				...challenge
			})
		} else {
			handleCreate({
				...body,
				...challenge
			})
		}
	}

	useEffect(() => {
		if (id) {
			if (isChallenge) {
				dispatch(getChallenge(id, (data) => initDetailForm(data)))
			} else {
				dispatch(getProgram(id, (data) => initDetailForm(data)))
			}
		} else {
			initEmptyDetailForm()
		}
		getAllLists()
	}, [dispatch, getAllLists, id, initDetailForm, initEmptyDetailForm])

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading || isLoadingPage}>
				{isChallenge ? <ChallengeForm onSubmit={handleSubmit} isCreate={!id} /> : <ProgramForm onSubmit={handleSubmit} isCreate={!id} />}
			</Spin>
		</div>
	)
}

export default DetailProgram
