import { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import map from 'lodash/map'
import { Spin } from 'antd'
import { change, getFormValues, initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createBlogPost, getBlogPost, updateBlogPost } from '../../redux/blog/actions'
import { getAdministrators } from '../../redux/administrators/actions'
import { getTags } from '../../redux/tags/actions'

// utils
import { BLOG_CONTENT_TYPE, BLOG_POST_TYPE, FORMS, LANGUAGE, LANGUAGES, ORDER_DIRECTION } from '../../utils/enums'
import { deserializeHTML } from '../../components/articleBuilder/customComponents/WYSIWYG'

// types
import { GetBlogPostsIdPayload, PostBlogPostsBody, PutBlogPostsIdBody } from '../../redux/blog/types'
import { IComputedMatch } from '../../types/interfaces'
import { ITEM_TYPES } from '../../components/articleBuilder/enums/enums'

// forms
import BlogForm from './forms/BlogForm'
import { BlogFormValues } from './forms/validateBlogForm'

type DetailBlogProps = {
	computedMatch: IComputedMatch
}

const DetailBlog = ({ computedMatch }: DetailBlogProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.BLOG_FORM)(state)) as BlogFormValues
	const detail = useSelector((state: RootState) => state.blog?.detail)
	const user = useSelector((state: RootState) => state.user?.profile) as any

	// states
	const [areaState, setAreaState] = useState<any>()
	const [afterInitHelper, setAfterInitHelper] = useState(false)

	// constants
	const isLoading = detail?.isLoading

	const getAllLists = () => {
		dispatch(getTags({ limit: 10000 }))
		dispatch(getAdministrators({ limit: 10000, orderBy: 'firstName', orderDirection: ORDER_DIRECTION.ASC }))
	}

	const initEmptyForm = () => {
		const langValues: any = {}
		const initValues = {
			contentUpdate: false,
			isPublished: false,
			notFeatured: false,
			author: {
				name: `${user?.firstName} ${user?.lastName}`,
				id: user?.id
			},
			authorName: `${user?.firstName} ${user?.lastName}`,
			authorID: user?.id,
			categories: [],
			tags: [],
			type: fieldValues?.type || BLOG_POST_TYPE.ARTICLE
		}

		forEach(LANGUAGES, (item: LANGUAGE) => {
			langValues[`name_${item}`] = null
			langValues[`urlSlug_${item}`] = null
		})

		dispatch(
			initialize(FORMS.BLOG_FORM, {
				...initValues,
				...langValues
			})
		)
		setAreaState(undefined)
	}

	const initDetailForm = (initData: GetBlogPostsIdPayload) => {
		const data = initData || detail?.data

		const blogPost = data?.blogPost
		const initValues = {
			...blogPost,
			labels: blogPost?.labels,
			author: blogPost?.author,
			authorName: blogPost?.author?.name,
			authorID: blogPost?.author?.id,
			tags: map(blogPost?.tags, (item) => item.id),
			categories: map(data?.categories, (item) => ({ id: item.id, title: item.name })),
			upsellProducts: map(data?.upsellProducts, (item: any) => ({ id: item.id, title: item.translations[0].name })),
			recommendedBlogPosts: data?.recommendedBlogPosts,
			isPublished: blogPost?.isPublished,
			publishedAt: dayjs(blogPost?.publishedAt),
			updatedAt: dayjs(blogPost?.updatedAt).format('H:mm:ss D. MMM YYYY '),
			contentUpdate: false,
			portions: get(blogPost, `content.items.${BLOG_CONTENT_TYPE.PORTIONS}.content`),
			portionType: get(blogPost, `content.items.${BLOG_CONTENT_TYPE.PORTION_TYPE}.content`),
			duration: get(blogPost, `content.items.${BLOG_CONTENT_TYPE.DURATION}.content`),
			imageAlt: blogPost?.imageAlt,
			imageTitle: blogPost?.imageTitle,
			pageMetadata: blogPost?.pageMetadata
		}

		delete initValues.content

		const content = cloneDeep(blogPost?.content)
		if (content?.items) {
			forEach(content.items, (item: any) => {
				if (item?.type === ITEM_TYPES.TEXT && item?.content?.editor) {
					// eslint-disable-next-line no-param-reassign
					item.content.editor = deserializeHTML(get(item, 'content.html', ''))
				}
			})
		}

		dispatch(initialize(FORMS.BLOG_FORM, initValues))
		setAreaState(content)
		setAfterInitHelper(true)
	}

	useEffect(() => {
		if (id) {
			dispatch(getBlogPost(id, (data: GetBlogPostsIdPayload) => initDetailForm(data)))
		} else {
			initEmptyForm()
		}
		getAllLists()
	}, [dispatch, id])

	useEffect(() => {
		if (!get(fieldValues, 'contentUpdate') && afterInitHelper) {
			dispatch(change(FORMS.BLOG_FORM, 'contentUpdate', true))
		}
	}, [areaState])

	const handleCreate = (body: PostBlogPostsBody) => dispatch(createBlogPost(body, (newId: number) => history.push(`${t('paths:blogDetail|path')}/${newId}`)))

	const handleUpdate = (body: PutBlogPostsIdBody) => {
		dispatch(
			updateBlogPost(id, body, () =>
				dispatch(
					getBlogPost(id, (data) => {
						initDetailForm(data)
					})
				)
			)
		)
	}

	const handleSubmit = (values: any) => {
		const body = {
			title: get(values, 'title'),
			briefDescription: get(values, 'briefDescription'),
			publishedAt: dayjs(values?.publishedAt).valueOf().toString(),
			isPublished: get(values, 'isPublished', false),
			urlSlug: get(values, 'urlSlug'),
			image: get(values, 'image'),
			imageAlt: get(values, 'imageAlt'),
			imageTitle: get(values, 'imageTitle'),
			language: get(values, 'language'),
			type: get(values, 'type'),
			content: areaState,
			authorID: get(values, 'authorID'),
			tags: get(values, 'tags') ? get(values, 'tags', ',') : [],
			labels: get(values, 'type') === BLOG_POST_TYPE.ARTICLE ? [] : get(values, 'labels'),
			categories: map(get(values, 'categories'), (item) => item.id),
			upsellProducts: map(get(values, 'upsellProducts'), (item) => item.id),
			recommendedBlogPosts: map(get(values, 'recommendedBlogPosts'), (item) => item.id),
			notFeatured: get(values, 'notFeatured'),
			pageMetadata: get(values, 'pageMetadata')
		}

		if (values.duration) {
			body.content = {
				...body.content,
				items: {
					...body.content.items,
					[BLOG_CONTENT_TYPE.DURATION]: {
						id: BLOG_CONTENT_TYPE.DURATION,
						type: BLOG_CONTENT_TYPE.DURATION,
						content: values.duration
					}
				}
			}
		}

		if (values.portions) {
			body.content = {
				...body.content,
				items: {
					...body.content.items,
					[BLOG_CONTENT_TYPE.PORTIONS]: {
						id: BLOG_CONTENT_TYPE.PORTIONS,
						type: BLOG_CONTENT_TYPE.PORTIONS,
						content: values.portions
					}
				}
			}
		}

		if (values.portionType || values.portionType === '') {
			body.content = {
				...body.content,
				items: {
					...body.content.items,
					[BLOG_CONTENT_TYPE.PORTION_TYPE]: {
						id: BLOG_CONTENT_TYPE.PORTION_TYPE,
						type: BLOG_CONTENT_TYPE.PORTION_TYPE,
						content: values.portionType
					}
				}
			}
		}

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
		setAfterInitHelper(false)
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<BlogForm onSubmit={handleSubmit} onUpdateForm={(areaData: any) => setAreaState(areaData)} isCreate={!id} blogBuilderData={areaState} />
			</Spin>
		</div>
	)
}

export default DetailBlog
